import React, { useEffect, useState } from 'react';

// Define the FileOrFolderItem interface here
interface FileOrFolderItem {
  id: string;
  name: string;
  type: string;
  connectionId: string;
  service: string;
  folders?: FileOrFolderItem[];
}

interface ChatProps {
  selectedFile: FileOrFolderItem | null; // Expecting a FileOrFolderItem or null
}

const Chat = () => {
  const [messages, setMessages] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  let selectedFile;
  useEffect(() => {
    if (selectedFile) {
      setMessages((prevMessages) => [
        ...prevMessages,
        `You have selected: ${selectedFile.name} (${selectedFile.type})`
      ]);
    }
  }, [selectedFile]);

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      setMessages((prevMessages) => [...prevMessages, inputValue]);
      setInputValue('');
    }
  };

  return (
    <div className="h-full flex flex-col border border-gray-300 rounded-lg p-4">
      <h2 className="text-xl font-semibold mb-2">Chat</h2>
      <div className="flex-grow overflow-y-auto mb-4">
        {messages.map((msg, index) => (
          <div key={index} className="mb-2 p-2 bg-gray-100 rounded">
            {msg}
          </div>
        ))}
      </div>
      <div className="flex">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="flex-grow border border-gray-300 rounded-l-lg p-2"
          placeholder="Type a message..."
        />
        <button
          onClick={handleSendMessage}
          className="bg-blue-500 text-white rounded-r-lg px-4"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
