import Chat from "../chat";
import FileList from "../filesList";
export default function Files(){
    return (
    <div>
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-3/5  p-4"><FileList /></div>
            <div className="w-full md:w-2/5 bg-gray-300 p-4"><Chat /></div>
        </div>
    </div>
)
}