import {
    Card,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card";
  import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
  } from "../../components/ui/breadcrumb";
  
  import googleDriveLogo from '../../assets/googledrive.jpeg';
  import oneDriveLogo from '../../assets/onedrive.png';
  import dropbox from '../../assets/dropbox.png';
  
  import { useEffect, useState } from 'react';
  
  const CLIENT_ID = '687381033136-tsqi3en9ol5dt7tdh73i8mgieflsiefr.apps.googleusercontent.com';
  const REDIRECT_URI = `${window.location.origin}/auth/callback`;
  const SCOPE =[ 'https://www.googleapis.com/auth/drive','https://www.googleapis.com/auth/documents.readonly'];
  const scopeString = SCOPE.join(' ');
  
  
  const connections = [
    { name: "Google Drive", icon: googleDriveLogo, spaceUsed: 75, service: "Google Drive" },
    { name: "OneDrive", icon: oneDriveLogo, spaceUsed: 50 },
    { name: "Dropbox", icon: dropbox, spaceUsed: 30 , service:"Dropbox"},
  ];
  
  export default function AddConnection() {
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
  
    // Define a function to handle incoming messages from the popup
    const handleMessage = async (event) => {
      if (event.origin !== window.location.origin) return;
  
      const { code, service } = event.data;
      
      if (code) {
        // Exchange authorization code for access token and refresh token
        const tokenResponse = await exchangeCodeForTokens(code,service);
        if (tokenResponse) {
          setAccessToken(tokenResponse.access_token);
          setRefreshToken(tokenResponse.refresh_token);
  
          // Send the access token and user ID to Cloudflare Worker
          await sendTokenToWorker(tokenResponse.access_token, tokenResponse.refresh_token, localStorage.getItem("user_id"),service);
        }
      }
    };
  
    // Effect to set up the message event listener onc
    useEffect(() => {
      window.addEventListener('message', handleMessage);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);
  
    const handleLogin = async (service) => {
      // Open a popup window for the Google OAuth 2.0 flow
      if (service === "Google Drive") {
        let service = "Google Drive";
        const AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${encodeURIComponent(scopeString)}&access_type=offline&prompt=consent&state=${service}`;
        return window.open(AUTH_URL, 'popup', 'width=600,height=600');
      }
      if (service === "Dropbox"){
        let url = `https://www.dropbox.com/oauth2/authorize?client_id=${import.meta.env.VITE_DROPBOX_Key}&response_type=code&redirect_uri=${window.location.origin}/auth/callback&state=${service}&token_access_type=offline`;
        console.log(url);
        return window.open(url,'popup','width=600,height=600');
      } 
      
    };
  
    const sendTokenToWorker = async (accessToken, refreshToken, userId,service) => {
      try {
        const response = await fetch("https://connections.cloudoone.workers.dev/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            access_token: accessToken,
            user_id: userId,
            service: service,
            refresh_token: refreshToken,
          }),
        });
  
        if (response.ok) {
          console.log("Access token and user ID successfully sent to Cloudflare Worker");
        } else {
          console.error("Failed to send access token and user ID", response.statusText);
        }
      } catch (error) {
        console.error("Error sending access token and user ID:", error);
      }
    };
  
    const exchangeCodeForTokens = async (code,service) => {
      if(service == "Google Drive"){
        const TOKEN_URL = 'https://oauth2.googleapis.com/token';
        const params = new URLSearchParams({
          code,
          client_id: CLIENT_ID,
          client_secret: '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', // Make sure to store this securely in production
          redirect_uri: REDIRECT_URI,
          grant_type: 'authorization_code',
        });
    
        try {
          const response = await fetch(TOKEN_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString(),
          });
    
          if (response.ok) {
            const data = await response.json();
            return data; // { access_token, refresh_token }
          } else {
            console.error('Error exchanging authorization code for tokens:', response.statusText);
          }
        } catch (error) {
          console.error('Error exchanging authorization code:', error);
        }
      }
      else if(service == "Dropbox"){
        const response = await fetch('https://api.dropboxapi.com/oauth2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            code: code,
            grant_type: 'authorization_code',
            client_id: import.meta.env.VITE_DROPBOX_Key,
            client_secret: import.meta.env.VITE_DROPBOX_Secret,
            redirect_uri: `${window.location.origin}/auth/callback`,
          }),
        });
      
        const data = await response.json();
        console.log(data);
        return data; // contains access_token, refresh_token, and more
      }
      
    };
  
    return (
      <>
        <div>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink>
                  <a href="/">Connections</a>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink>
                  <a href="/components">addConnections</a>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
            </BreadcrumbList>
          </Breadcrumb>
        </div>
  
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {connections.map((connection) => (
            <Card onClick={() => { handleLogin(connection.service) }} key={connection.name} className="border rounded-lg p-4 transition-all duration-200 hover:shadow-md cursor-pointer">
              <CardHeader className="flex flex-col items-center space-y-2 pb-2">
                <img src={connection.icon} alt={connection.name} className="h-8 w-8" />
                <CardTitle className="text-sm font-medium text-center">
                  {connection.name}
                </CardTitle>
              </CardHeader>
            </Card>
          ))}
        </div>
      </>
    );
  }
  
  
  
  