import Sidebar from '../components/layout/SideBar';
import Header from '../components/layout/Header';
import ContentArea from '../components/layout/MainContent';
import { useState } from 'react';


const MainLayout = ({ children }) => {

  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="flex h-screen">
      <Sidebar setIsOpen={setIsOpen} isOpen = {isOpen}/>
        <ContentArea isOpen = {isOpen} >
          {children}
        </ContentArea>
    </div>
  );
};

export default MainLayout;
