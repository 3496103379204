"use client"

import { useState } from "react"
import { Button } from "../ui/button"
import { ScrollArea } from "../ui/scroll-area"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip"
import { ChevronRight, ChevronLeft, Home, Network, FileIcon, Package } from "lucide-react"
import { useNavigate } from 'react-router-dom'

export default function Sidebar({setIsOpen, isOpen}) {
  
  const [selectedItem, setSelectedItem] = useState("Home")
  const toggleSidebar = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  return (
    <aside
      className={`bg-secondary text-secondary-foreground transition-all duration-300 ease-in-out ${
        isOpen ? "w-64" : "w-16"
      } flex flex-col fixed top-0 left-0 h-full z-50`}
    >
      {/* Brand section */}
      <div className="p-4 border-b border-secondary-foreground/10">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center space-x-2">
                <Package className="h-6 w-6 flex-shrink-0" />
                {isOpen && <span className="font-bold truncate">Brand Name</span>}
              </div>
            </TooltipTrigger>
            {!isOpen && <TooltipContent side="right">Brand Name</TooltipContent>}
          </Tooltip>
        </TooltipProvider>
      </div>

      {/* Navigation */}
      <ScrollArea className="flex-grow">
        <nav className="space-y-1 p-2">
          <SidebarItem
            icon={<Home className="h-4 w-4" />}
            text="Dashboard"
            isOpen={isOpen}
            isSelected={selectedItem === "Dashboard"}
            onClick={() => {setSelectedItem("Dashboard"); navigate('/Dashboard')}}
          />
          <SidebarItem
            icon={<FileIcon className="h-4 w-4" />}
            text="Files"
            isOpen={isOpen}
            isSelected={selectedItem === "Files"}
            onClick={() => {setSelectedItem("Files");navigate("/Files")}}
          />
          <SidebarItem
            icon={<Network className="h-4 w-4" />}
            text="Connections"
            isOpen={isOpen}
            isSelected={selectedItem === "Connections"}
            onClick={() => {setSelectedItem("Connections"); navigate("/Connections")}}
          />
        </nav>
      </ScrollArea>

      {/* Collapse/Expand button */}
      <Button
        variant="ghost"
        size="icon"
        className="absolute -right-3 top-9 w-6 h-6 transform -translate-y-1/2 rounded-full bg-primary text-primary-foreground"
        onClick={toggleSidebar}
      >
        {isOpen ? <ChevronLeft className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
        <span className="sr-only">{isOpen ? "Collapse sidebar" : "Expand sidebar"}</span>
      </Button>
    </aside>
  )
}

function SidebarItem({
  icon,
  text,
  isOpen,
  isSelected,
  onClick,
}: {
  icon: React.ReactNode
  text: string
  isOpen: boolean
  isSelected: boolean
  onClick: () => void
}) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={isSelected ? "secondary" : "ghost"}
            size="sm"
            className={`w-full justify-start ${isSelected ? "bg-primary text-primary-foreground" : ""} ${
              isOpen ? "px-4" : "px-0"
            }`}
            onClick={onClick}
          >
            {isOpen ? (
              <>
                {icon}
                <span className="ml-2 truncate">{text}</span>
              </>
            ) : (
              <div className="mx-auto">{icon}</div>
            )}
          </Button>
        </TooltipTrigger>
        {!isOpen && <TooltipContent side="right">{text}</TooltipContent>}
      </Tooltip>
    </TooltipProvider>
  )
}
