"use client"

import React, { useState, useMemo, useEffect } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { Input } from "./ui/input"
import { ScrollArea } from "./ui/scroll-area"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./ui/collapsible"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui/dropdown-menu"
import { Button } from "./ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Label } from "./ui/label"

import { FileIcon, DockIcon, ImageIcon, SheetIcon, FileTextIcon, FileArchiveIcon as FileZipIcon, VideoIcon, FileQuestionIcon, FolderIcon, ChevronRightIcon, MoreVerticalIcon, CalendarIcon, FilterIcon } from 'lucide-react'
import { format } from 'date-fns'

const connectionMap: { [key: string]: string } = {}
const connections: { id: string; name: string; icon: string }[] = []

const FileTypeIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'folder':
      return <FolderIcon className="w-5 h-5 text-yellow-500" />
    case 'image':
      return <ImageIcon className="w-5 h-5 text-blue-500" />
    case 'pdf':
      return <FileIcon className="w-5 h-5 text-red-500" />
    case 'doc':
      return <FileTextIcon className="w-5 h-5 text-green-500" />
    case 'zip':
      return <FileZipIcon className="w-5 h-5 text-purple-500" />
    case 'text':
      return <FileTextIcon className="w-5 h-5 text-gray-500" />
    case 'video':
      return <VideoIcon className="w-5 h-5 text-pink-500" />
    case "spreadsheet":
      return <SheetIcon className="w-5 h-5 text-green-500" />
    case "docx":
      return <DockIcon className="w-5 h-5 text-blue-500" />
    case "document":
      return <DockIcon className="w-5 h-5 text-blue-500" />
    case "word":
      return <DockIcon className="w-5 h-5 text-blue-500" />
    default:
      return <FileQuestionIcon className="w-5 h-5 text-gray-400" />
  }
}

interface FileOrFolderItem {
  id: string
  name: string
  type: string
  connectionId: string
  service: string
  folders?: FileOrFolderItem[]
  createdDate: Date
  modifiedDate: Date
  size: number
}

interface FileOrFolderProps {
  item: FileOrFolderItem
  level: number
  selectedConnection: string
  searchTerm: string
  advancedFilters: AdvancedSearchFilters | null
}

interface AdvancedSearchFilters {
  createdFrom: Date | null
  createdTo: Date | null
  modifiedFrom: Date | null
  modifiedTo: Date | null
  sizeFrom: number | null
  sizeTo: number | null
  fileType: string | null
  connectionId: string | null
}

interface AdvancedSearchProps {
  onSearch: (filters: AdvancedSearchFilters) => void
  connections: { id: string; name: string; icon: string }[]
}

const ConnectionIcon = ({ connectionId }: { connectionId: string }) => {
  const connection = connections.find(conn => conn.id === connectionId)
  return <span className="text-lg flex-shrink-0" aria-hidden="true">{connection ? connection.icon : '🔴'}</span>
}

const isItemVisible = (
  item: FileOrFolderItem,
  selectedConnection: string,
  searchTerm: string,
  advancedFilters: AdvancedSearchFilters | null
): boolean => {
  const matchesSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase())
  const matchesConnection = !selectedConnection || selectedConnection === 'All' || item.connectionId === selectedConnection

  let matchesAdvancedFilters = true
  if (advancedFilters) {
    const { createdFrom, createdTo, modifiedFrom, modifiedTo, sizeFrom, sizeTo, fileType, connectionId } = advancedFilters
    
    if (createdFrom && item.createdDate < createdFrom) matchesAdvancedFilters = false
    if (createdTo && item.createdDate > createdTo) matchesAdvancedFilters = false
    if (modifiedFrom && item.modifiedDate < modifiedFrom) matchesAdvancedFilters = false
    if (modifiedTo && item.modifiedDate > modifiedTo) matchesAdvancedFilters = false
    if (sizeFrom && item.size < sizeFrom) matchesAdvancedFilters = false
    if (sizeTo && item.size > sizeTo) matchesAdvancedFilters = false
    if (fileType && item.type !== fileType) matchesAdvancedFilters = false
    if (connectionId && connectionId !== 'All' && item.connectionId !== connectionId) matchesAdvancedFilters = false
  }

  if (item.type === 'folder' && item.folders) {
    return (matchesSearch && matchesConnection && matchesAdvancedFilters) || item.folders.some(child => isItemVisible(child, selectedConnection, searchTerm, advancedFilters))
  }

  return matchesSearch && matchesConnection && matchesAdvancedFilters
}

const FileOrFolder: React.FC<FileOrFolderProps> = ({ item, level, selectedConnection, searchTerm, advancedFilters }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isVisible = useMemo(() => isItemVisible(item, selectedConnection, searchTerm, advancedFilters), [item, selectedConnection, searchTerm, advancedFilters]);

  if (!isVisible) {
    return null;
  }

  const handleContextMenuAction = (action: string) => {
    console.log(`Action ${action} triggered for ${item.name}`);
    // Implement the action logic here
  };

  const commonClasses = "flex items-center w-full text-left p-2 rounded-lg hover:bg-gray-100 overflow-hidden";
  const indentStyle = { paddingLeft: `${level * 0.5}rem` };

  return (
    <div className="py-1">
      {item.type === 'folder' ? (
        <Collapsible open={isOpen}>
          <CollapsibleTrigger 
            className={commonClasses}
            style={indentStyle}
            onClick={() => setIsOpen(prev => !prev)}
          >
            <div className="flex items-center min-w-0 flex-grow overflow-hidden">
              <ChevronRightIcon className="flex-shrink-0 w-4 h-4 mr-2 transition-transform duration-200" style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }} />
              <FileTypeIcon type={item.type} />
              <span className="font-medium truncate mx-2 flex-grow">{item.name}</span>
            </div>
            <div className="flex items-center space-x-2 flex-shrink-0">
              <ConnectionIcon connectionId={item.connectionId} />
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <MoreVerticalIcon className="w-5 h-5" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => handleContextMenuAction('move')}>Move to</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleContextMenuAction('copy')}>Copy to</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleContextMenuAction('delete')}>Delete</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleContextMenuAction('checkDuplicates')}>Check for duplicates</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            {item.folders && item.folders.map((child) => (
              <FileOrFolder 
                key={child.id} 
                item={child} 
                level={level + 1}
                selectedConnection={selectedConnection}
                searchTerm={searchTerm}
                advancedFilters={advancedFilters}
              />
            ))}
          </CollapsibleContent>
        </Collapsible>
      ) : (
        <div 
          className={commonClasses}
          style={{ ...indentStyle, paddingLeft: `${(level + 1) * 0.5}rem` }}
        >
          <div className="flex items-center min-w-0 flex-grow overflow-hidden">
            <FileTypeIcon type={item.type} />
            <span className="truncate mx-2 flex-grow">{item.name}</span>
          </div>
          <div className="flex items-center space-x-2 flex-shrink-0">
            <ConnectionIcon connectionId={item.connectionId} />
            <DropdownMenu>
              <DropdownMenuTrigger>
                <MoreVerticalIcon className="w-5 h-5" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => handleContextMenuAction('move')}>Move to</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleContextMenuAction('copy')}>Copy to</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleContextMenuAction('delete')}>Delete</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleContextMenuAction('checkDuplicates')}>Check for duplicates</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
    </div>
  );
};

const ShimmerItem: React.FC<{ level: number }> = ({ level }) => (
  <div 
    className="flex items-center space-x-2 p-2 rounded-lg animate-pulse overflow-hidden"
    style={{ paddingLeft: `${level * 0.5}rem` }}
  >
    <div className="w-5 h-5 bg-gray-200 rounded-sm flex-shrink-0"></div>
    <div className="h-4 bg-gray-200 rounded flex-grow"></div>
    <div className="w-5 h-5 bg-gray-200 rounded-sm flex-shrink-0"></div>
  </div>
)

const ShimmerLoader: React.FC = () => (
  <div className="space-y-2">
    {[...Array(10)].map((_, index) => (
      <ShimmerItem key={index} level={index % 3} />
    ))}
  </div>
)

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({ onSearch, connections }) => {
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState<AdvancedSearchFilters>({
    createdFrom: null,
    createdTo: null,
    modifiedFrom: null,
    modifiedTo: null,
    sizeFrom: null,
    sizeTo: null,
    fileType: null,
    connectionId: null,
  })

  const handleDateChange = (field: keyof AdvancedSearchFilters, value: Date | null) => {
    setFilters(prev => ({ ...prev, [field]: value }))
  }

  const handleInputChange = (field: keyof AdvancedSearchFilters, value: string) => {
    setFilters(prev => ({ ...prev, [field]: value ? Number(value) : null }))
  }

  const handleSelectChange = (field: keyof AdvancedSearchFilters, value: string) => {
    setFilters(prev => ({ ...prev, [field]: value }))
  }

  const handleSearch = () => {
    onSearch(filters)
    setOpen(false)
  }

  const handleReset = () => {
    setFilters({
      createdFrom: null,
      createdTo: null,
      modifiedFrom: null,
      modifiedTo: null,
      sizeFrom: null,
      sizeTo: null,
      fileType: null,
      connectionId: null,
    })
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="ml-2 px-2 sm:px-4">
          <FilterIcon className="h-4 w-4 sm:mr-2" />
          <span className="hidden sm:inline">Advanced</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Created Date</h4>
            <div className="grid grid-cols-2 gap-2">
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-full justify-start text-left font-normal">
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {filters.createdFrom ? format(filters.createdFrom, 'PPP') : <span>From</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  {/* <Calendar
                    mode="single"
                    selected={filters.createdFrom}
                    onSelect={(date) => handleDateChange('createdFrom', date)}
                    initialFocus
                  /> */}
                </PopoverContent>
              </Popover>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-full justify-start text-left font-normal">
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {filters.createdTo ? format(filters.createdTo, 'PPP') : <span>To</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  {/* <Calendar
                    mode="single"
                    selected={filters.createdTo}
                    onSelect={(date) => handleDateChange('createdTo', date)}
                    initialFocus
                  /> */}
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Modified Date</h4>
            <div className="grid grid-cols-2 gap-2">
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-full justify-start text-left font-normal">
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {filters.modifiedFrom ? format(filters.modifiedFrom, 'PPP') : <span>From</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  {/* <Calendar
                    mode="single"
                    selected={filters.modifiedFrom}
                    onSelect={(date) => handleDateChange('modifiedFrom', date)}
                    initialFocus
                  /> */}
                </PopoverContent>
              </Popover>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-full justify-start text-left font-normal">
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {filters.modifiedTo ? format(filters.modifiedTo, 'PPP') : <span>To</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  {/* <Calendar
                    mode="single"
                    selected={filters.modifiedTo}
                    onSelect={(date) => handleDateChange('modifiedTo', date)}
                    initialFocus
                  /> */}
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className="space-y-2">
            <h4 className="font-medium leading-none">File Size (KB)</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="space-y-2">
                <Label htmlFor="sizeFrom">From</Label>
                <Input
                  id="sizeFrom"
                  type="number"
                  value={filters.sizeFrom || ''}
                  onChange={(e) => handleInputChange('sizeFrom', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="sizeTo">To</Label>
                <Input
                  id="sizeTo"
                  type="number"
                  value={filters.sizeTo || ''}
                  onChange={(e) => handleInputChange('sizeTo', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <h4 className="font-medium leading-none">File Type</h4>
            <Select onValueChange={(value) => handleSelectChange('fileType', value)} value={filters.fileType || undefined}>
              <SelectTrigger>
                <SelectValue placeholder="Select file type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="folder">Folder</SelectItem>
                <SelectItem value="image">Image</SelectItem>
                <SelectItem value="pdf">PDF</SelectItem>
                <SelectItem value="doc">Document</SelectItem>
                <SelectItem value="zip">Archive</SelectItem>
                <SelectItem value="text">Text</SelectItem>
                <SelectItem value="video">Video</SelectItem>
                <SelectItem value="spreadsheet">Spreadsheet</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Connection</h4>
            <Select onValueChange={(value) => handleSelectChange('connectionId', value)} value={filters.connectionId || undefined}>
              <SelectTrigger>
                <SelectValue placeholder="Select connection" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="All">All Connections</SelectItem>
                {connections.map((conn) => (
                  <SelectItem key={conn.id} value={conn.id}>
                    {conn.icon} {conn.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-between">
            <Button onClick={handleReset} variant="outline">Reset</Button>
            <Button onClick={handleSearch}>Apply Filters</Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default function FileList() {
  const [selectedConnection, setSelectedConnection] = useState<string>('All')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [advancedFilters, setAdvancedFilters] = useState<AdvancedSearchFilters | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<FileOrFolderItem[]>([])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      try {
        const response = await fetch('https://files.cloudoone.workers.dev/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: localStorage.getItem("user_id"),
            requestType: 'files'
          }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const fetchedData = await response.json();
        fetchedData.folders.forEach((element: FileOrFolderItem) => {
          if(!connectionMap[element.connectionId]){
            connectionMap[element.connectionId] = "rr";
            connections.push({id: element.connectionId, name: element.service, icon: "🔵"})
          }
        });
        setData(fetchedData.folders);
      } catch (error) {
        console.error('Failed to fetch files:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [])

  const handleAdvancedSearch = (filters: AdvancedSearchFilters) => {
    setAdvancedFilters(filters)
    if (filters.connectionId) {
      setSelectedConnection(filters.connectionId)
    }
  }

  return (
    <div className="flex flex-col h-screen max-w-full border border-gray-200 rounded-lg overflow-hidden">
      <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-between p-2 sm:p-4 bg-background space-y-2 sm:space-y-0 sm:space-x-2 border-b border-gray-200">
        <div className="flex items-center space-x-2 w-full">
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow"
          />
          <AdvancedSearch onSearch={handleAdvancedSearch} connections={connections} />
        </div>
      </div>
      <ScrollArea className="flex-grow">
        <div className="p-2 sm:p-4">
          {isLoading ? (
            <ShimmerLoader />
          ) : (
            data.map((item) => (
              <FileOrFolder 
                key={item.id} 
                item={item} 
                level={0} 
                selectedConnection={selectedConnection} 
                searchTerm={searchTerm}
                advancedFilters={advancedFilters}
              />
            ))
          )}
        </div>
      </ScrollArea>
    </div>
  )
}