import { Progress } from "@radix-ui/react-progress";
import { ServiceType } from "../enum";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { useEffect, useState } from "react";

import googleDriveLogo from '../../assets/googledrive.jpeg'



const fetchQuotaData = async (userId) => {
    try {
      const response = await fetch('https://files.cloudoone.workers.dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
          requestType: 'usage' // This specifies that you want quota information
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching quota data:", error);
      throw error;
    }
  };

export default function Dashboard(){
    const [connections, setConnections] = useState({connections:[]})
    useEffect(() => {
        const fetchData = async () => {
          try {
            setConnections(await fetchQuotaData(localStorage.getItem("user_id")));
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [])
    return (
        <>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">Dashboard</h1>
        </div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {connections.connections.map((connection) => (
            <Card key={connection.name}>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  {ServiceType[connection.service]}
                </CardTitle>
                <img src={connection.service == "GOOGLE_DRIVE" ? googleDriveLogo : googleDriveLogo} alt={connection.name} className="h-8 w-8" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{((connection.usage / connection.limit) * 100).toFixed(2)}%</div>
                <p className="text-xs text-muted-foreground">Space utilized</p>
                <Progress value={Number(((connection.usage / connection.limit) * 100).toFixed(2))}  />
              </CardContent>
            </Card>
          ))}
        </div>
      </>)
}