// src/enums.ts
export enum ServiceType {
    GOOGLE_DRIVE = "Google Drive",
    DROPBOX = "Dropbox",
    ONE_DRIVE = "OneDrive",
    BOX = "Box",
  }
export enum ServiceLogo {
    GOOGLE_DRIVE = "Google Drive",
    DROPBOX = "Dropbox",
    ONE_DRIVE = "OneDrive",
    BOX = "Box",
}